<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Store"
        title="Sales By Payment Type"
        subTitle="How Is My Business Doing?"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter" @reset="onReset">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormDate
                  label="Date From"
                  mask="date"
                  v-model="dateFrom"
                  :value="dateFrom"
                  :rules="[VALIDATION.required]"
                  @input="onSelectFromDate"
                  tabindex="1"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormDate
                  label="Date To"
                  mask="date"
                  v-model="dateTo"
                  :value="dateTo"
                  :rules="[VALIDATION.required]"
                  @input="onSelectToDate"
                  tabindex="2"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <SelectUserStores
                  v-model="store"
                  :values="store"
                  @updateStore="$event => (store = $event)"
                  tabindex="3"
                />
              </div>

              <div
                class="flex justify-end gap-4 col-xs-12 col-sm-12 col-md-3 text-right"
              >
                <Button
                  variant="primary"
                  label="Reset"
                  icon="refresh"
                  type="reset"
                  outline
                  tabindex="4"
                />
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="!isFiltered && loadingSales"
                  :disabled="!isFiltered && loadingSales"
                  type="submit"
                  tabindex="5"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="!isFiltered && loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="col q-col-gutter-md" v-if="hasData">
        <div>
          <q-card class="q-pa-lg">
            <q-card-section>
              <HorizontalBarChart
                id="SalesByPaymentType"
                type="SalesByPaymentType"
                axis="xy"
                xLabel="Net Sales"
                yLabel="Payment Type"
                xData="netSales"
                yData="paymentType"
                :data="salesTableData"
              />
            </q-card-section>
          </q-card>
        </div>
        <div>
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            @onRequest="onRequest"
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Sales By Payment Type</h5>
    <p>Generate sales by payment type by date range and store</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Filters</h6>
    <p><strong>Date From:</strong> required field</p>
    <p><strong>Date To:</strong> required field</p>
    <p><strong>Store:</strong> required field</p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard, NoDataCard } from '@/components/cards'
import { FormDate } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import { HorizontalBarChart } from '@/components/charts'
import Loader from '@/components/Loader'
import SelectUserStores from '@/components/customs/SelectUserStores'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  fetchData,
  getIconUrl
} from '@/tools'

export default {
  name: 'StoreSalesByPaymentType',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    NoDataCard,
    FormDate,
    Button,
    Table,
    Modal,
    HorizontalBarChart,
    Loader,
    SelectUserStores
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)

    const filterForm = ref(null)
    const dateFrom = ref(DATE.toFriendlyDate(new Date()))
    const dateTo = ref(DATE.toFriendlyDate(new Date()))
    const store = ref([])
    const salesTableData = ref(null)

    const salesTableHeader = ref([
      {
        name: 'paymentType',
        label: 'Payment Type',
        field: 'paymentType',
        align: 'left',
        sortable: true
      },
      {
        name: 'netSales',
        label: 'Net Sales',
        field: row => FORMAT.toCurrency(row.netSales),
        align: 'center',
        sortable: true
      },
      {
        name: 'transCount',
        label: 'Transaction Count',
        field: 'transCount',
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )
    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (success) {
          const payload = {
            pagination: {
              page: pageNo.value,
              per_page: limitPage.value
            },
            filters: {
              date_from: DATE.toFriendlyDate(dateFrom.value),
              date_to: DATE.toFriendlyDate(dateTo.value),
              stores: store.value
            }
          }

          postSales('v1/sales/sales-by-payment-type', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      dateFrom.value = DATE.toFriendlyDate(new Date())
      dateTo.value = DATE.toFriendlyDate(new Date())
      store.value = []
      salesTableData.value = null
      limitPage.value = 10
      pageNo.value = 1
      totalCount.value = 0
    }

    const onSelectFromDate = data => {
      dateFrom.value = data
    }

    const onSelectToDate = data => {
      dateTo.value = data
    }

    const exportTable = () => {
      EXPORT.exportToExcel(
        salesTableHeader.value,
        salesTableData.value,
        'sales_by_payment_type'
      )
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast(
              errorSales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (dataSales?.value) {
            totalCount.value = dataSales?.value?.total

            salesTableData.value = dataSales?.value?.map(item => ({
              paymentType: item.payment_type_name,
              netSales: item.sales,
              transCount: FORMAT.toNumber(item.trx_count)
            }))
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      showModal,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      dateFrom,
      dateTo,
      store,
      salesTableHeader,
      salesTableData,
      loadingSales,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onFilter,
      onReset,
      onSelectFromDate,
      onSelectToDate,
      exportTable,
      onRequest
    }
  }
}
</script>
