<template>
  <store-sales-by-payment-type />
</template>

<script>
import StoreSalesByPaymentType from '@/components/pages/sales/StoreSalesByPaymentType'

export default {
  components: {
    StoreSalesByPaymentType
  }
}
</script>
